/* eslint-disable no-console */
const { mergeObject } = require('@/helpers')
const { selectApiCMSEndpoint } = require('@/helpers/select-endpoint')
const { isPreview } = require('@/helpers/content')
const HTTP_STATUS_HEALTHY = [
  301,
  404
]
const _API_RESPONSE_TIME_TO_PRINT_LOG = 300
/**
 * API Helpers
 */
async function errorHandler (error, redirect, lang) {
  const {
    MOVED_PERMANENTLY, // 301
    NOT_FOUND, // 404
    INTERNAL_SERVER_ERROR // 500
  } = await require('http-status')
  const { response } = error
  if (response && response.status === MOVED_PERMANENTLY) {
    redirect(response.data)
  } else if (response && response.status === NOT_FOUND) {
    redirect(`/${lang}/error/404`)
  } else if (response && response.data && response.status === INTERNAL_SERVER_ERROR) {
    redirect(`/${lang}/error/500`)
  } else {
    console.error('API CMS - Under construction:', error)
    redirect(`/${lang}/error/503`)
  }
}

// const isPreview = (contentUrl) => {
//   return /\/preview\//i.test(contentUrl)
// }

/**
 * Initial plugin
 */
export default function ({ $axios, redirect, app, $cookies, $device, route }, inject) {
  /**
   * Set instance
   */
  console.log('debug:plugin', {
    CMS_SSR_BASE_URL: process.env.CMS_SSR_BASE_URL,
    ENABLE_DEBUGGING: process.env.ENABLE_DEBUGGING
  })
  const enableDebugging = process.env.ENABLE_DEBUGGING === 'true'
  const ENV_USE_DEVELOPMENT = process.env.USE_DEVELOPMENT === 'true'
  const isDevelopment = ENV_USE_DEVELOPMENT ? true : process.env.NODE_ENV === 'development'
  // const apiKey = process.env.API_CMS_KEY
  const webChannel = process.env.WEB_CHANNEL
  const requestTime = new Date().getTime()
  const instanceOption = {
    // baseURL: process.server || isDevelopment ? process.env.HOST_PROXY : '/',
    baseURL: isDevelopment ? process.env.HOST_PROXY : '/',
    meta: {
      requestBeforeStartedAt: requestTime,
      requestStartedAt: requestTime
    },
    headers: {
      common: {
        // 'x-api-key': apiKey,
        'x-channel': webChannel
      }
    }
  }

  // Create a custom axios instance
  const axiosInstance = $axios.create(instanceOption)
  axiosInstance.interceptors.request.use((config) => {
    // Multisite CMS
    const { url, method, baseURL: defaultBaseURL } = config ?? {}
    const { endpoint, requestPath } = selectApiCMSEndpoint({
      url,
      method,
      isServerToServer: process.server,
      defaultBaseURL
    })
    // console.log({ endpoint, requestPath, defaultBaseURL })
    if (requestPath) {
      config.url = requestPath
    }
    if (endpoint) {
      config.baseURL = endpoint
    }
    // if (process.server) {
    //   console.log('=================================================')
    //   console.log('[INFO]', 'Page URL:', route.fullPath)
    //   console.log('[INFO]', '[Request]API CMS - path:', config.url)
    //   console.log('[INFO]', '[Request]API CMS - params:', config.params || '-')
    //   console.log('[INFO]', '[Request]API CMS - endpoint:', config.baseURL)
    // }
    config.requestStartedAt = new Date().getTime()

    if (process.server) {
      const { isMobile } = $device
      const device = isMobile ? 'mobile' : 'desktop'
      config.headers['x-device'] = device
    }

    const clientId = app.store.state.clientId || ''
    if (clientId) {
      config.headers['x-client-uuid'] = clientId
    }
    const uref = $cookies.get('uref') || ''
    const userRef = parseInt(uref, 10) || 0
    if (userRef) {
      config.headers['x-userid'] = userRef
    }

    const { info } = app.store.state.contents.content || {}
    const { pageUuid } = (info || {})
    if (pageUuid) {
      config.headers['x-page-uuid'] = (pageUuid || '')
    }
    if (isPreview(config.params?.path)) {
      config.headers['x-content'] = 'preview'
    }
    return config
  }, (x) => {
    if (process.server) {
      console.log('=================================================')
      console.error('Page URL:', route.fullPath)
      console.error('[Request]API CMS - Error details:', x)
    }
    // console.log('[Request]API CMS - Headers:', x.config.headers)
    throw x
  })

  axiosInstance.interceptors.response.use((x) => {
    if (process.server) {
      const respTime = new Date().getTime() - x.config.meta.requestStartedAt
      const printRespLog = respTime >= _API_RESPONSE_TIME_TO_PRINT_LOG
      if (printRespLog) {
        console.log('=================================================')
        console.log('[INFO]', 'Page URL:', route.fullPath)
        console.log('[INFO]', '[Response]API CMS - status:', x.status, enableDebugging, process.env.ENABLE_DEBUGGING)
        console.log('[INFO]', `[Response - ${new Date()}]API CMS - Execution time for: ${x.config.baseURL}${x.config.url} - ${respTime} ms`)
        if (enableDebugging) {
          console.log('[INFO]', '[Response]API CMS - headers:', x.config.headers)
        }
      }
    }

    return x
  }, (x) => {
    if (process.server) {
      const { errorConfig: { isSpecificPage } } = x.config
      const { NOT_FOUND, MOVED_PERMANENTLY } = require('http-status')
      const isErrorByContentChecker = isSpecificPage === true && x.response?.status === NOT_FOUND
      if (isErrorByContentChecker === true) {
        console.log('')
        console.log('[DEBUG]', 'Content checker - status:', x.response?.status)
        console.log('')
      }
      if (isErrorByContentChecker === false) {
        if (x.response?.status === MOVED_PERMANENTLY) {
          console.log('=================================================')
          console.log('[INFO]', 'API CMS - status:', x.response?.status)
          console.log('[INFO]', 'API CMS - redirect:', x.response?.data)
        } else {
          console.log('=================================================')
          console.error('Page URL:', route.fullPath)
          console.error('[Response]API CMS - status:', x.response?.status)
          console.error('[Response]API CMS - headers:', x.config.headers)
          console.error(`[Response - ${new Date()}]API CMS - Error response time for: ${x.config.baseURL}${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
          console.error('[Response]API CMS - Error details:', x)
        }
      }
    }
    throw x
  })

  const request = async function (config) {
    const { errorConfig: { errorRedirect } } = config
    try {
      const response = await axiosInstance(config)
      return response
    } catch (error) {
      const { response } = error
      if (errorRedirect === true || !HTTP_STATUS_HEALTHY.includes(response?.status)) {
        const { i18n: { locale } } = app
        errorHandler(error, redirect, locale)
      } else {
        const { response } = error
        return response
      }
    }
  }

  request.$get = function (path, config) {
    return request(mergeObject({ method: 'get', url: path, progress: false }, config))
  }
  request.$post = function (path, data, config) {
    return request(mergeObject({ method: 'post', url: path, data, progress: false }, config))
  }

  // Inject to context as $api
  inject('apiPage', request)
}
