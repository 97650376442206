// import { isValidUrl } from '~/plugins/url-validator'
import { setClientId, isInvalidPath } from '@/helpers'
import isFunctionalPage from '~/helpers/functionalPage'

// Helper - SET SOURCE and Media Center
async function replaceSetSourceURL ({ path }) {
  if (!path?.includes('/about/setsource')) { return null }
  const newPath = await path
    .replace('/setsource/', '/mediacenter/') // Replace path name /setsource/ with /mediacenter/
    .replace('/setsource-vdo', '/vdo') // Replace path name /setsource-vdo with /vdo
  return newPath
}

export default async function ({
  store,
  route,
  $cookies,
  $userAgent,
  redirect
}) {
  try {
    // Check user agent
    $userAgent.checkUserAgent()

    // Replace path name /setsource/ with /mediacenter/
    const mediacenterURL = await replaceSetSourceURL({ path: route.path })
    if (mediacenterURL) {
      redirect(mediacenterURL)
      return
    }

    // handle invalid path | example: /th/?fbclid=
    const invalidPath = isInvalidPath(route.fullPath)
    if (invalidPath === true) {
      redirect('/')
      return
    }

    // Some pages do not require content data
    const _isFunctionalPage = isFunctionalPage(route.name)
    if (_isFunctionalPage) { return }

    // Set client id
    if (process.server) {
      const clientUuidFromCookie = $cookies.get('clientUuid')
      setClientId(clientUuidFromCookie).then((uuid) => {
        const { options } = require('@/config/cookies')
        store.commit('setClientId', uuid)
        $cookies.set('clientUuid', uuid, options)
      })
    }

    if (route.fullPath.includes('/market/index') && !route.fullPath.includes('/gms-exchanges/trading')) {
      await store.dispatch('contents/getContentMarketIndex', route)
    } else if (route.fullPath.includes('/information/member-list')) {
      await store.dispatch('contents/getContentMemberList', route)
    } else {
      await store.dispatch('contents/getContent', {
        ...route,
        redirect
      })
    }
  } catch (error) {
  }
}
