//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'MyQuote',
  data () {
    return {
      tabActive: 'recentlyView',
      quoteFavorite: 0,
      updateRecentlyViewed: 0,
      myFavoriteQuote: [],
      category: 'หุ้น',
      active: false,
      cookiesName: 'myquote-popup-hidden',
      showButtonMyQuote: true
    }
  },
  computed: {
    ...mapGetters({
      closeModal: 'setmember/favorite/getCloseModal'
    }),
    myQuotePopupHidden () {
      return this.$cookies.get(this.cookiesName)
    }
  },
  watch: {
    closeModal () {
      this.getMyFavoriteQuote()
    }
  },
  mounted () {
    if (this.tabActive) {
      this.initialTab()
    }
  },
  methods: {
    ...mapActions({
      getFavoriteSymbolsTable: 'setmember/getFavoriteSymbolsTable',
      setSuggesttionByWriter: 'quote/setSuggesttionByWriter',
      setCategoryOption: 'setmember/favorite/setCategoryOption'
    }),
    handleCloseMyQuote () {
      const alreadyCookie = this.myQuotePopupHidden === 0 || this.myQuotePopupHidden === 1
      if (alreadyCookie) { return }
      const d = dayjs().add(7, 'day') // new Date(date)
      const expiresDate = d.$d // new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59) // expires at midnight
      const option = {
        path: '/',
        expires: expiresDate
      }
      this.$cookies.set(this.cookiesName, 1, option)
      this.showButtonMyQuote = false
    },
    showMyQuote () {
      const myQuote = document.querySelector('.my-quote-content')
      const btnMyQuote = document.querySelector('.btn-my-quote')
      const bullChatBot = document.getElementById('zwiz-web-chat')
      if (bullChatBot) {
        bullChatBot.classList.add('myquote-active')
      }
      document.body.classList.add('my-quote-open')
      const screenWidth = window.innerWidth
      myQuote.style.display = 'flex'
      this.active = true
      if (screenWidth >= 768) {
        myQuote.classList.add('slide-in')
      } else {
        myQuote.classList.add('verticle-slide-in')
        this.disableScrolling()
      }
      myQuote.classList.remove('slide-out')
      myQuote.classList.remove('verticle-slide-out')
      if (btnMyQuote && btnMyQuote.classList) {
        btnMyQuote.classList.remove('fade-in')
        // btnMyQuote.style.display = 'none'
        btnMyQuote.style.visibility = 'hidden'
      }
      const tabName = (localStorage.getItem('isOpenMyQuote') === 'null' || localStorage.getItem('isOpenMyQuote') === null) ? 'Close' : localStorage.getItem('isOpenMyQuote')
      if (tabName === 'Close') {
        this.changeTab('recentlyView')
        this.getMyFavoriteQuote()
      }
    },
    hideMyQuote () {
      const myQuote = document.querySelector('.my-quote-content')
      const btnMyQuote = document.querySelector('.btn-my-quote')
      const bullChatBot = document.getElementById('zwiz-web-chat')
      const screenWidth = window.innerWidth
      myQuote.classList.remove('slide-in')
      myQuote.classList.remove('verticle-slide-in')
      this.active = false
      if (screenWidth >= 768) {
        myQuote.classList.add('slide-out')
      } else {
        myQuote.classList.add('verticle-slide-out')
        this.enableScrolling()
      }
      setTimeout(() => {
        // btnMyQuote.style.display = 'block'
        // myQuote.style.display = 'none'
        btnMyQuote.style.visibility = null
        myQuote.style.display = 'none'
        if (bullChatBot) {
          bullChatBot.classList.remove('myquote-active')
        }
        document.body.classList.remove('my-quote-open')
      }, 650)
      btnMyQuote.classList.add('fade-in')
      localStorage.setItem('isOpenMyQuote', 'Close')
    },
    disableScrolling () {
      const doc = window.document.body
      doc.style.overflow = 'hidden'
    },
    enableScrolling () {
      const doc = window.document.body
      doc.style.overflow = 'auto'
    },
    initialTab () {
      const tabName = (localStorage.getItem('isOpenMyQuote') === 'null' || localStorage.getItem('isOpenMyQuote') === null) ? 'Close' : localStorage.getItem('isOpenMyQuote')
      if (tabName !== 'Close') {
        this.showMyQuote()
        this.changeTab(tabName)
      }
    },
    changeTab (tabName) {
      this.tabActive = tabName
      if (tabName === 'favorite') {
        this.getMyFavoriteQuote()
      }
      if (tabName === 'recentlyView') {
        this.activeRecentlyViewed()
      }
      localStorage.setItem('isOpenMyQuote', tabName)
      // this.showMyQuote()
    },
    saveTabByTabActive () {
      localStorage.setItem('isOpenMyQuote', this.tabActive)
      this.handleClickTracking('myQuote1', { menu: this.tabActive })
    },
    onClickViewMore () {
      this.handleClickTracking('myQuote3')
      this.toMyFavorite()
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    },
    setTypeQuote (value) {
      this.category = value
      this.getMyFavoriteQuote()
    },
    LastCheckStautsCategory (chg) {
      if (this.category === chg) {
        return this.category
      } else if (this.category === undefined) {
        return chg
      }
    },
    activeRecentlyViewed () {
      this.updateRecentlyViewed += 1
    },
    async getMyFavoriteQuote () {
      let stock, etf, dr, drx, dw //, options, fund, futures
      const isLogin = await this.$authUser.isLogin()
      if (isLogin) {
        if (this.category === 'หุ้น' || this.category === 'Stock') {
          stock = await this.getFavoriteSymbolsTable('stock')
          this.myFavoriteQuote = stock && stock.favorites.length > 0 ? [...stock.favorites.filter((r) => { return r.isActive })] : []
        }
        if (this.category === 'ETF') {
          etf = await this.getFavoriteSymbolsTable('etf')
          this.myFavoriteQuote = etf && etf.favorites.length > 0 ? [...etf.favorites.filter((r) => { return r.isActive })] : []
        }
        if (this.category === 'DR') {
          dr = await this.getFavoriteSymbolsTable('dr')
          this.myFavoriteQuote = dr && dr.favorites.length > 0 ? [...dr.favorites.filter((r) => { return r.isActive })] : []
        }
        if (this.category === 'DRx') {
          drx = await this.getFavoriteSymbolsTable('drx')
          this.myFavoriteQuote = drx && drx.favorites.length > 0 ? [...drx.favorites.filter((r) => { return r.isActive })] : []
        }
        if (this.category === 'DW') {
          dw = await this.getFavoriteSymbolsTable('dw')
          this.myFavoriteQuote = dw && dw.favorites.length > 0 ? [...dw.favorites.filter((r) => { return r.isActive })] : []
        }
        // if (this.category === 'ออปชัน') {
        //   options = await this.getFavoriteSymbolsTable('options')
        //   this.myFavoriteQuote = options && options.favorites.length > 0 ? [...options.favorites] : []
        // }
        // if (this.category === 'กองทุนรวม') {
        //   fund = await this.getFavoriteSymbolsTable('fund')
        //   this.myFavoriteQuote = fund && fund.favorites.length > 0 ? [...fund.favorites] : []
        // }
        // if (this.category === 'ฟิวเจอร์ส') {
        //   futures = await this.getFavoriteSymbolsTable('futures')
        //   this.myFavoriteQuote = futures && futures.favorites.length > 0 ? [...futures.favorites] : []
        // }
        this.myFavoriteQuote = this.sortMyFavoriteQuote(this.myFavoriteQuote)
        this.setCategoryOption(this.category)
        this.setSuggesttionByWriter({
          stock: this.myFavoriteQuote,
          type: this.category
        })
      }
    },
    sortMyFavoriteQuote (items) {
      items = items.sort(function (a, b) {
        const symbolA = a.symbol.toUpperCase()
        const symbolB = b.symbol.toUpperCase()
        if (symbolA < symbolB) {
          return -1
        }
        if (symbolA > symbolB) {
          return 1
        }
        return 0
      })
      return items
    },
    async toMyFavorite () {
      const isLogin = await this.$authUser.isLogin()
      if (isLogin) {
        if (!this.$device.isDesktop) {
          this.hideMyQuote()
        }
        this.$router.push(
          this.localePath({
            name: 'member-index-favorites'
          })
        )
      } else {
        const url = `${this.$i18n.locale}/member/favorites`
        // console.log(this.localePath('member/favorites'))
        window.location.href = this.$authUser.login(url)
      }
    }

  }
}
